<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="isOpenModal"
      :close-on-click-modal="false"
      width="500px"
      @closed="closeModal" @open="openModal">
      <div>
        <ch-form :render-option="option" :model="modalForm" :rules=rules
                 :props="{ labelWidth: '90px' , paddingRight: '0'}"
                  ref="modalForm"
        >
        </ch-form>
        <span slot="footer" class="flex_con">
          <ch-button @click="closeModal" type="cancel">取消</ch-button>
          <ch-button
            @click="handleModal('modalForm')"
            :loading="isLoading"
            type="ok" >确定</ch-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    getListFunction: {
      type: Function
    }
  },
  data(){
    return {
      title: '',
      type: '',
      modalForm: {
        price:null,
        type:null,
        classifyId:null,
      },
      isOpenModal: false,
      isLoading: false,
      rules: {
        type: [{required: true, message: "请选择增购服务类型", trigger: ["blur", "change"],}],
        classifyId: [{required: true, message: "请选择分类", trigger: ["blur", "change"],}],
        name: [{required: true, message: "请输入增值名称", trigger: ["blur", "change"],}],
        // describe: [{required: true, message: "请输入增值描述", trigger: ["blur", "change"],}],
        price: [{required: true, message: "请输入价格", trigger: ["blur", "change"],}],
        status: [{required: true, message: "请选择状态", trigger: ["blur", "change"],}],
      },
      classifyOption:[],
    }
  },
  computed: {
    option: function () {
      return [
        {type: 'select', label: '类型：', prop: 'type', placeholder: '请选择增购服务类型',
          name: "name", value: "id", option: [
            { name: "增购服务", id: 0 },
            { name: "增加人数", id: 1 },
            { name: "增加时长", id: 2 },
          ]},
        {type: 'select', label: this.modalForm.type===null? '分类：': this.modalForm.type===0? '一级分类：': '二级分类：', prop: 'classifyId', placeholder: this.modalForm.type===null? '请选择分类': this.modalForm.type===0? '请选择一级分类':'请选择二级分类',
          name: "name", value: "id", option: this.classifyOption,props:{disabled:this.isClassifyId} },
        {type: 'input', label: '增购服务：', prop: 'name', placeholder: "请输入增购服务名称"},
        {type: 'input', label: '描述：', prop: 'describe', placeholder: "请输入描述"},
        {type: 'number', label: '单价：', prop: 'price', placeholder: "请输入价格",precision:2},
        {type: 'select', label: '状态：', prop: 'status', placeholder: "请选择状态",
          name: "name", value: "id", option: [
            { name: "禁用", id: 0 },
            { name: "启用", id: 1 },
          ]}
      ]
    },
    isClassifyId: function (){
      return !(this.modalForm.type || this.modalForm.type === 0);
    }
  },
  watch:{
    'modalForm.price': function (val){
      if (val){
        this.$nextTick(() => {
          this.$refs.modalForm.clearValidate('price')
        })
      }
    },
    'modalForm.type': function (val,oldVal){
      if(val===0){
        this.getClassify()
      }else{
        this.getSecondList()
      }
      if(oldVal &&val!==oldVal){
        this.modalForm.classifyId = null
      }
    },

  },
  methods: {
    // 打开前处理
    openModal() {
      this.$nextTick(() => {
        this.$refs.modalForm?.clearValidate();
      })
    },
    openAdd(){
      this.title = '新增增购服务'
      this.type = 'add'
      this.isOpenModal = true
    },
    openEdit(row){
      this.title = '编辑增购服务'
      this.type = 'edit'
      this.isOpenModal = true
      this.modalForm = JSON.parse(row)
      console.log(JSON.parse(row))
    },
    // 服务分类 一级
    getClassify(){
      this.$curl.get('/hm/classify/firstList').then(res=>{
        this.classifyOption = res.data
      })
    },
    // 服务分类 二级
    getSecondList(){
      this.$curl.get('/hm/classify/secondList').then(res=>{
        this.classifyOption = res.data
      })
    },
    // 执行 增加 或 修改
    handleModal(formRef) {
      this.$refs[formRef].validate((valid) => {
        if (valid) {
          this.isLoading = true
          let url = this.type === 'add' ? '/hm/goodsAppreciation/add' : '/hm/goodsAppreciation/edit'
          let message = this.type === 'add' ? '新增成功!' : '修改成功!'
          let method = this.type === 'add' ? 'post' : 'put'
          delete this.modalForm.companyId
          delete this.modalForm.delFlag
          this.$curl[method](url,this.modalForm)
              .then(() => {
                this.$message.success(message);
                this.getListFunction?.()
                this.isLoading = false
                this.closeModal()
              }).catch(() => this.isLoading = false)
        }
      })
    },
    // 关闭前清除数据
    closeModal() {
      this.modalForm = {}
      this.editId = ''
      this.isOpenModal = false
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
</style>